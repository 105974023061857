import { render, staticRenderFns } from "./Books.vue?vue&type=template&id=bc65c32e&scoped=true"
import script from "./Books.vue?vue&type=script&lang=ts"
export * from "./Books.vue?vue&type=script&lang=ts"
import style0 from "./Books.vue?vue&type=style&index=0&id=bc65c32e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc65c32e",
  null
  
)

export default component.exports